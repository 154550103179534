/*//////////////////////////////////////////////*/
/* table */
/*//////////////////////////////////////////////*/

.table {
  border-collapse: collapse;
  width: 80%; /* 幅 */
}
.th, .td {
  padding: 5px 15px;
  border: solid 1px #a5d1f1;
}
.th {
  background-color: #d0ebfd;
}
.no {
    text-align:"right";
    background-color:"black";
}

/*//////////////////////////////////////////////*/
/* image */
/*//////////////////////////////////////////////*/
.img {
    border: 1px black solid;
    margin-top: 20px;
    margin-bottom: 50px;
}