.MuiTableCell-head {
  font-weight: bold;
  padding: 0px 16px;
  height: 41px;
}

.MuiButton-label {
  font-weight: bold;
}

.MuiToolbar-regular {
  padding: 0;
  min-height: 36px;
}

.MuiTabs-root {
  min-height: 36px;
  flex-grow: 1;
}

.MuiTab-root {
  min-height: 36px;
}

.MuiTab-wrapper {
  font-weight: bold;
}

.MuiTypography-root.typography-head {
  font-weight: bold;
  text-align: center;
  font-size: 0.9rem;
  padding: 8px 16px;
  height: 40px;
  background-color: #fafafa;
}

.MuiTypography-root.typography-label {
  font-weight: bold;
  font-size: 14px;
}

.MuiTypography-root.edit-label {
  font-weight: bold;
}

.MuiAvatar-root {
  border-radius: 0%;
}
